import { Injectable } from '@angular/core';
import { isUndefined, isNullOrUndefined } from 'is-what';

import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  constructor(private router:Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
    .pipe(catchError((err: any, caught) => {
      if (err instanceof HttpErrorResponse) {
        // console.log("interceptor err ", err);
        if(!isNullOrUndefined(err["status"])) {
          if(err.status == 401) {
            // console.log("401 ", err);
            localStorage.clear()
             this.router.navigate([''])
          }
        }
        return throwError(err);
      }
    }));
    
  }
}
